import React, { createContext, useContext, useState } from 'react';
import init from "../../src/data/setup/init.json"

const LogoContext = createContext();

export function useLogoContext() {
  return useContext(LogoContext);
}

export function LogoProvider({ children }) {
  const defaultLogo = init.headerLogo
  const changeToLogo = init.brandLogoMapping[0]

  const [selectedLogo, setSelectedLogo] = useState(defaultLogo);

  const changeLogo = (newLogo) => {
    setSelectedLogo(newLogo);
  };

  return (
    <LogoContext.Provider value={{ selectedLogo, changeLogo }}>
      {children}
    </LogoContext.Provider>
  );
}
