// UrlContext.js to remember user url before login
import { createContext, useContext, useState } from 'react';

const UrlContext = createContext();

export function UrlProvider({ children }) {
  const [previousLocation, setPreviousLocation] = useState(null);

  return (
    <UrlContext.Provider value={{ previousLocation, setPreviousLocation }}>
      {children}
    </UrlContext.Provider>
  );
}

export function useUrlContext() {
  return useContext(UrlContext);
}
