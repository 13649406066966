import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice"
import { LogoProvider } from "./hooks/logo-context"; // Import the LogoProvider
import { SupabaseProvider } from "./helpers/supa-base";
import { UrlProvider} from "./helpers/url-context";
// import localProducts from "./data/products.json";
import init from "./data/setup/init.json"
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";

// const fs = require('fs');
// const filePath = 'catchProducts.json';

const site = (init.multiBrandSites.length > 1) ? init.multiBrandSites : init.siteName
const fetchData = () => {
  const payload = {
    q: 'web_items',
    siteName: site,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return fetch('https://us-central1-kinetic-object-314621.cloudfunctions.net/webDeals', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    });
};

fetchData()
  .then(products => {
    store.dispatch(setProducts(products));
  })
    // if (products.length !== 0) {
    //   store.dispatch(setProducts(products));
      // const jsonString = JSON.stringify(products, null, 2); // The `null, 2` parameters add formatting for readability
      // fs.writeFile(filePath, jsonString, 'utf-8', (err) => {
      //   if (err) {
      //     console.error('Error writing JSON file:', err);
      //   } else {
      //     console.log('JSON file saved successfully.');
      //   }
      // });
  //   }
  //   store.dispatch(setProducts(localProducts))
  // })
  .catch(error => {
    console.error('Error in data fetching:', error);
  });

// store.dispatch(setProducts(products));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
      <PersistProvider>
        <SupabaseProvider>
          <UrlProvider>
            <LogoProvider>
              <App />
            </LogoProvider>
          </UrlProvider>
        </SupabaseProvider>
      </PersistProvider>
    </Provider>
);

